.search-input{
    width: 50%;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    color: #333;
    outline: none;
    transition: border-color 0.3s;
}

@media (max-width: 768px) {
  .search-input {
    width: 100%;
  }
}